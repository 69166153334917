import React from 'react';

const initialState = {
  metal: '',
  selectedMetal: '',
  viewedMetals: [],
  ligand: '',
  selectedLigand: '',
  viewedLigands: [],
  acceptedDisclaimer: false,
  hasSubmitted: false
};

const actions = {
  SET_METAL: "SET_METAL",
  SET_SELECTED_METAL: "SET_SELECTED_METAL",
  SET_VIEWED_METALS: "SET_VIEWED_METALS",
  SET_LIGAND: "SET_LIGAND",
  SET_SELECTED_LIGAND: "SET_SELECTED_LIGAND",
  SET_VIEWED_LIGANDS: "SET_VIEWED_LIGANDS",
  SET_DISCLAIMER: "SET_DISCLAIMER"
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_METAL:
      return {
        ...state,
        metal: action.value
      };
    case actions.SET_SELECTED_METAL:
      return {
        ...state,
        selectedMetal: action.value
      };
    case actions.SET_VIEWED_METALS:
      return {
        ...state,
        viewedMetals: [...state.viewedMetals, action.value]
      };
    case actions.SET_LIGAND:
      return {
        ...state,
        ligand: action.value
      };
    case actions.SET_SELECTED_LIGAND:
      return {
        ...state,
        selectedLigand: action.value
      };
    case actions.SET_VIEWED_LIGANDS:
      return {
        ...state,
        viewedLigands: [...state.viewedLigands, action.value]
      };
    case actions.SET_DISCLAIMER:
      return {
        ...state,
        acceptedDisclaimer: action.value
      };
      case actions.SET_SUBMITTED:
        return {
          ...state,
          hasSubmitted: action.value
        }
    default:
      return state;
  }
};

const WizardContext = React.createContext();

const WizardProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const value = {
    metal: state.metal,
    selectedMetal: state.selectedMetal,
    viewedMetals: state.viewedMetals,
    ligand: state.ligand,
    selectedLigand: state.selectedLigand,
    viewedLigands: state.viewedLigands,
    acceptedDisclaimer: state.acceptedDisclaimer,
    hasSubmitted: state.hasSubmitted,
    setMetal: (value) => {
      dispatch({
        type: actions.SET_METAL,
        value
      });
    },
    setSelectedMetal: (value) => {
      dispatch({
        type: actions.SET_SELECTED_METAL,
        value
      });
    },
    setViewedMetals: (value) => {
      dispatch({
        type: actions.SET_VIEWED_METALS,
        value
      });
    },
    setLigand: (value) => {
      dispatch({
        type: actions.SET_LIGAND,
        value
      });
    },
    setSelectedLigand: (value) => {
      dispatch({
        type: actions.SET_SELECTED_LIGAND,
        value
      });
    },
    setViewedLigands: (value) => {
      dispatch({
        type: actions.SET_VIEWED_LIGANDS,
        value
      });
    },
    setDisclaimer: (value) => {
      dispatch({
        type: actions.SET_DISCLAIMER,
        value
      });
    },
    setSubmitted : (value) => {
      dispatch({
        type: actions.SET_SUBMITTED,
        value
      })
    }
  };

  return (
    <WizardContext.Provider value={value}>
      {children}
    </WizardContext.Provider>
  );
}

function useWizard() {
  const context = React.useContext(WizardContext);

  if (!context) {
    throw new Error(`useWizard must be used from within <WizardProvider />`);
  }

  return context;
}

export {
  WizardProvider,
  useWizard
};
