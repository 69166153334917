const ligands = [
    'acetato',
    'acetylacetonato',
    'allyl',
    'amido',
    'aminopentenonato',
    'bistrimethylsilylamido',
    'bromo',
    'chloro',
    'cyano',
    'cyclopentadienyl',
    'diethylacetamidinato',
    'diethylamido',
    'diethyldithiocarbamato',
    'diisopropylacetamidinato',
    'diisopropylamido',
    'dimethylacetamidinato',
    'dimethylamido',
    'dimethylaminoethoxy',
    'dimethylaminomethylbutoxy',
    'dimethylaminomethylpropoxy',
    'dimethylaminopropoxy',
    'dimethylglyoximato',
    'ditertbutylacetamidinato',
    'ditertbutylamido',
    'ethoxy',
    'ethyl',
    'ethylmethylamido',
    'fluoro',
    'formato',
    'heptafluorodimethyloctanedionato',
    'hexafluoroacetylacetonato',
    'hydrido',
    'imidazolyl',
    'iodo',
    'isocyanato',
    'isopropoxy',
    'isopropyl',
    'isothiocyanato',
    'methoxy',
    'methoxymethylpropoxy',
    'methyl',
    'methylcyclopentadienyl',
    'nitrato',
    'nitrito',
    'nitrosyl',
    'pentamethylcyclopentadienyl',
    'pyrazolate',
    'pyrrolyl',
    'tertbutoxy',
    'tertbutyl',
    'tetramethylguanidinato',
    'tetramethylheptanedionato',
    'tetramethylphospaguanidinato',
    'thioacetylacetonato',
    'triflato',
    'trifluoroacetylacetonato',
    'trimethylisoureato'
];

export default ligands;
