import React, { useEffect, useState, useRef } from 'react';
import queryString from 'query-string';
import { useForm } from 'react-hook-form';
import { useWizard } from './context/context';
import { trailingSlashIt } from './utilities/utilities';

import logo from '../img/RASIRC_logo.png';

const ContactForm = () => {
  const {
    register,
    watch,
    handleSubmit,
    errors,
    reset,
    formState
  } = useForm();
  const {
    metal,
    ligand,
    hasSubmitted,
    viewedMetals,
    viewedLigands
  } = useWizard();

  const { isSubmitSuccessful } = formState;
  const [isLoading, setIsLoading] = useState(false);
  const [submittedData, setSubmittedData] = useState({});
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const formElem = useRef();

  const onSubmit = (data) => {
    const query = queryString.stringify(data);
    const url = `${trailingSlashIt(window.location.origin)}${formElem.current.dataset.submitUrl}?${query}`;

    // set loading state
    setIsLoading(true);
    // update state with form data
    setSubmittedData(data);

    // post to server for emailin'
    fetch(url)
      .then((response) => response.text())
      .then(() => {
        // update state form was successfully submitted
        setWasSubmitted(true);
        setIsLoading(false);
      });

  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  },  [isSubmitSuccessful, submittedData, reset, formState]);

  const watchName = watch('inputFullName', '');
  const watchEmail = watch('inputEmail', '');

  if (hasSubmitted) {
    return (
      <div id="contact-rasirc" className="ald-contact-form">
        <div className="container">
          <img className="contact__img" src={logo} alt="Rasirc logo" />
          <div className="contact__container">
            <div className="primary">
              <h2 className="contact__heading">
                How can we help?
              </h2>
              <p className="contact__body">
              For information on how Brute Peroxide® and the Peroxidizer® can deliver hydrogen peroxide gas to enable these reactions in your process, please contact us by filling out your information in the form. We will respond within 24 hours.
              </p>
            </div>
            <div className="secondary">
              <form
                noValidate
                onSubmit={handleSubmit(onSubmit)}
                className="contact__form"
                data-submit-url="ald-mail-handler.php"
                ref={formElem}
              >
                <div className="contact__form-item">
                  <label className="contact__label" htmlFor="inputFullName">Full Name<sup>*</sup></label>
                  <input
                    className="contact__input"
                    id="inputFullName"
                    name="inputFullName"
                    type="text"
                    required
                    aria-invalid={errors.inputFullName ? "true" : "false"}
                    ref={register({ required: true })}
                  />
                  {errors.inputFullName && errors.inputFullName.type === "required" && (
                    <span className="error" role="alert">Your name is required.</span>
                  )}
                </div>

                <div className="contact__form-item">
                  <label className="contact__label" htmlFor="inputEmail">Email Address<sup>*</sup></label>
                  <input
                    className="contact__input"
                    id="inputEmail"
                    name="inputEmail"
                    type="text"
                    required
                    aria-invalid={errors.inputEmail ? "true" : "false"}
                    ref={register({ required: true })}
                  />
                  {errors.inputEmail && errors.inputEmail.type === "required" && (
                    <span className="error" role="alert">Your email is required.</span>
                  )}
                </div>

                <div className="contact__form-item">
                  <label className="contact__label" htmlFor="inputCompany">Company</label>
                  <input
                    className="contact__input"
                    id="inputCompany"
                    name="inputCompany"
                    type="text"
                    ref={register}
                  />
                </div>

                <div className="contact__form-item is-hidden">
                  <input
                    className="contact__input is-hidden"
                    id="inputMetal"
                    value={metal}
                    name="inputMetal"
                    type="hidden"
                    ref={register}
                  />
                  <input
                    className="contact__input is-hidden"
                    id="inputLigand"
                    value={ligand}
                    name="inputLigand"
                    type="hidden"
                    ref={register}
                  />
                  <input
                    className="contact__input is-hidden"
                    id="inputViewedMetals"
                    value={viewedMetals.join(',')}
                    name="inputViewedMetals"
                    type="hidden"
                    ref={register}
                  />
                  <input
                    className="contact__input is-hidden"
                    id="inputViewedLigands"
                    value={viewedLigands.join(',')}
                    name="inputViewedLigands"
                    type="hidden"
                    ref={register}
                  />
                </div>

                <div className="contact__form-item">
                  <label className="contact__label" htmlFor="inputMessage">Message</label>
                  <textarea
                    className="contact__input"
                    id="inputMessage"
                    name="inputMessage"
                    rows="7"
                    ref={register}
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-primary btn-filled btn--cta"
                  disabled={!watchName || !watchEmail || errors.inputFullName || errors.inputEmail}
                >
                  Submit
                </button>
              </form>
              {isLoading &&
                <div className="contact__response-msg is-loading">
                  <div className="is-relative">
                    <div className="spinner">
                      <div className="double-bounce1"></div>
                      <div className="double-bounce2"></div>
                    </div>
                  </div>
                </div>
              }
              {wasSubmitted && !isLoading &&
                <div className="contact__response-msg">
                  <h3 className="response-msg__heading">Thank you for your inquiry!</h3>
                  <p>
                    One of our hydrogen peroxide product specialists will contact you within 24 hours. In the meantime, you can find more information on <a href="https://www.rasirc.com/product-brute-peroxide.html">Brute Peroxide</a>, <a href="https://www.rasirc.com/product-peroxidizer.html">the Peroxidizer</a>, and <a href="https://www.rasirc.com/news/news-whitepapers.html">technical papers</a> on the use of H<sub>2</sub>O<sub>2</sub> gas in thin film processing.
                  </p>
                  <p>&mdash;The Team at RASIRC</p>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return null;
  }
};

export default ContactForm;
