import React from 'react';
import { useWizard } from './context/context';
import {
  isBlank,
  formulaFormat,
  findNearestHundred,
  getPercentage,
  getDirectionClass,
  trailingSlashIt
} from './utilities/utilities';
import data from '../data/json/data';

const IconButton = (props) => {
  const handleButtonClick = (e) => {
    e.preventDefault();
    e.target.dispatchEvent(new CustomEvent('setCanvasImage', {
      bubbles: true
    }));

    setTimeout(() => {
      window.print();
    }, 250);
  };

  return (
    <button onClick={handleButtonClick} className="button--icon" type="button">
      {props.children}
    </button>
  );
};

const FinePrint = () => {
  const pdf = 'data/pdf/Technical-White-Paper-ALD-Oxide-Wizard.pdf';

  return (
    <>
      <p>
        The bar graph above displays the reaction energy needed to form the oxide. The more negative the value, the more thermodynamically likely the reaction is to occur.
      </p>
      <div className="alloy-graph__actions">
        <IconButton>
          <svg className="icon icon--print" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19">
            <g fill="#29D9C2">
              <path d="M19 3.92742231h-4.7085951V0H4.72963609v3.90576841L0 3.90659763V14.3934521h4.72880278V19h9.56176882l.0008137-7.302458h1.3956208v-.8491724H2.89617681v.8491724h1.81227372v1.8467801H.83316134V4.77640491H18.1657162v8.78850969l-3.6871704.0008292v.8491724h4.5204763L19 3.92742231zm-5.562942 7.77035199v6.4532856H5.56294201v-6.4532856H13.437058zM5.56294201.8491724H13.458099V3.9057684l-7.89515699.00082922V.84917239z"/>
              <path d="M4 7.5C4 8.33316876 4.66683124 9 5.5 9S7 8.33316876 7 7.5 6.33316876 6 5.5 6 4 6.66683124 4 7.5zm2.27751308 0c0 .42598993-.33341562.75940555-.75940555.75940555C5.09211761 8.25868236 4.74063148 7.90719734 4.74063148 7.5c0-.40719734.33341562-.75940555.75940555-.75940555.42526785 0 .77747605.33341562.77747605.75940555zM7 16h4v1H7zM7 13h4v1H7z"/>
            </g>
          </svg>
          <span>Print</span>
        </IconButton>

        <a className="button--icon" href="#contact-rasirc">
          <svg className="icon icon--email" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 13">
            <path fill="#29D9C2" d="M.92016122 0C.43154812 0 0 .4171375 0 .92857143V12.0714286C0 12.5828625.43154812 13 .92016122 13H20.0798388C20.5684519 13 21 12.5828625 21 12.0714286V.92857143C21 .4171375 20.5684519 0 20.0798388 0H.92016122zm.44939804 1.39285714H19.6303494v.79799107l-9.1303951 6.47817858-9.13039504-6.47817858v-.79799107zm0 2.48833929l4.20865562 2.98883928-4.20865562 3.84474999V3.88119643zm18.26079014 0v6.83382147l-4.2086556-3.84475004 4.2086556-2.98907143zM6.73366638 7.68973214l3.37390922 2.39385716c.2353816.1668527.5492389.1668527.7846433 0l3.3739093-2.39385716 4.2940248 3.91741076H2.43929918l4.2943672-3.91741076z"/>
          </svg>
          <span>Contact Rasirc</span>
        </a>
      </div>
      <p className="small small--dark">
        The wizard is powered by Schrödinger® software. If you are interested in learning more about the science behind the ALD Oxide Wizard, <a className="fineprint__link" href={`${trailingSlashIt(window.location.origin)}${pdf}`}>read the Whitepaper</a>.
      </p>
    </>
  );
};

const Graph = () => {
  const { metal, ligand, hasSubmitted } = useWizard();
  const hasValues = !isBlank(metal) && !isBlank(ligand) ? true : false;

  if (hasSubmitted && hasValues) {
    const alloy = data[metal.toLowerCase()][ligand.toLowerCase()];
    const numArr = [alloy.h2o, alloy.h202];
    const scale = findNearestHundred(numArr);
    const h2oPerc = getPercentage(alloy.h2o, scale);
    const h2o2Perc = getPercentage(alloy.h202, scale);
    const h2oDir = getDirectionClass(alloy.h2o);
    const h2o2Dir = getDirectionClass(alloy.h2O2);

    return (
      <>
        {hasSubmitted && hasValues &&
          <div className="alloy-graph">
            <h2 className="alloy-graph__formula" dangerouslySetInnerHTML={{ __html: formulaFormat(alloy.targetOxide)}} />
            <span className="alloy-graph__sub-head molecule__sub-head">
              Target Oxide
            </span>
            <div className="alloy-graph__graph-box">
              <div className="alloy-graph__graph alloy-graph__graph-one">
                <div className="alloy-graph__inner">
                  <span className={`alloy-graph__num ${h2oDir}`}>{`${alloy.h2o} kJ/mol`}</span>
                  <svg style={{ height: `${h2oPerc / 2}%` }} className={`alloy-graph__bar ${h2oDir}`}>
                    <rect x="0" y="0" />
                  </svg>
                  <div className="quad quad-pos" />
                  <div className="quad quad-neg" />
                </div>
                <p className="alloy-graph__label">H<sub>2</sub>O</p>
              </div>

              <div className="alloy-graph__graph alloy-graph__graph-two">
                <div className="alloy-graph__inner">
                  <span className={`alloy-graph__num ${h2o2Dir}`}>{`${alloy.h202} kJ/mol`}</span>
                  <svg style={{ height: `${h2o2Perc / 2}%` }} className={`alloy-graph__bar ${h2o2Dir}`}>
                    <rect x="0" y="0" />
                  </svg>
                  <span style={{ height: `${h2o2Perc / 2}%` }} className={`alloy-graph__bar ${h2o2Dir}`} />
                  <div className="quad quad-pos" />
                  <div className="quad quad-neg" />
                </div>
                <p className="alloy-graph__label">H<sub>2</sub>O<sub>2</sub></p>
              </div>
            </div>
            <FinePrint />
          </div>
        }
      </>
    );
  } else if (hasSubmitted) {
    return (
      <div className="alloy-graph">
        <h2 className="alloy-graph__formula" />
        <div className="alloy-graph__graph-box">
          <div className="alloy-graph__graph alloy-graph__graph-one">
            <div className="alloy-graph__inner">
              <div className="quad quad-pos" />
              <div className="quad quad-neg" />
            </div>
            <p className="alloy-graph__label">H<sub>2</sub>O</p>
          </div>

          <div className="alloy-graph__graph alloy-graph__graph-two">
            <div className="alloy-graph__inner">
              <div className="quad quad-pos" />
              <div className="quad quad-neg" />
            </div>
            <p className="alloy-graph__label">H<sub>2</sub>O<sub>2</sub></p>
          </div>
        </div>
        <FinePrint />
      </div>
    )
  } else {
    return null;
  }
};

export default Graph;
