import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useCombobox } from 'downshift';
import { matchSorter } from 'match-sorter';
import { useWizard } from './context/context';
function DropdownCombobox(props) {
  const {
    listItems,
    label,
    name,
    printLabel
  } = props;
  const [inputItems, setInputItems] = useState(listItems);
  const {
    setSelectedMetal,
    setSelectedLigand,
    setViewedMetals,
    setViewedLigands
  } = useWizard();

  const getItems = (value) =>
  value ? matchSorter(listItems, value) : listItems

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    reset,
    selectItem,
    selectedItem
  } = useCombobox({
    items: inputItems,
    onInputValueChange: ({inputValue}) => {
      setInputItems(
        getItems(inputValue)
      )
    },
    onSelectedItemChange: (changes) => {
      if (name === 'metals') {
        let metal = changes.selectedItem !== null ? changes.selectedItem : '';

        if (metal !== '') {
          setViewedMetals(metal);
        }

        setSelectedMetal(metal);
      } else {
        let ligand = changes.selectedItem !== null ? changes.selectedItem : '';

        if (ligand !== '') {
          setViewedLigands(ligand);
        }

        setSelectedLigand(ligand);
      }
    }
  });

  const customProp = {}
  customProp['--print-label'] = `${printLabel}`;

  return (
    <div className="combo-box" style={customProp}>
      <label htmlFor={`input-${name}`} className="combobox__label" {...getLabelProps()}>{ label }</label>
      <div {...getComboboxProps()}>
        <input
          id={`input-${name}`}
          name={`input-${name}`}
          className="combobox__input"
          {...getInputProps({
            onChange: e => {
              if (e.target.value === '') {
                reset()
              }
            },
          })}
          placeholder='Start typing or select using the arrow'
        />
        {!selectedItem &&
          <button
            className="combobox__button"
            type="button"
            {...getToggleButtonProps()}
            aria-label="toggle menu"
          >
            <svg className="combobox_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 6">
              <path fill="#FFF" d="M5.725048 5.7031174L9.776357 1.395237C10.29181.8484165 9.860622 0 9.050446 0H.947828C.147921 0-.293977.8388202.221918 1.395237l4.051309 4.3078804c.378581.3958435 1.073228.3958435 1.451842 0h-.000021z"/>
            </svg>
          </button>
        }

        {selectedItem !== null && selectedItem !== '' &&
          <button
            className="combobox__button"
            type="button"
            onClick={() => {
              selectItem(null)
            }}
            aria-label="clear selection"
          >
            <svg className="combobox_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 11">
              <defs/>
              <path fill="#fff" d="M11 9.4L7.2 5.5 11 1.7 9.4 0 5.5 3.8 1.7 0 0 1.7l3.8 3.8L0 9.4 1.7 11l3.8-3.8L9.4 11 11 9.4z"/>
            </svg>
          </button>
        }
      </div>
      <ul
        className={
          isOpen ? 'combobox__menu is-open' : 'combobox__menu'
        } {...getMenuProps()}
      >
        {isOpen &&
          inputItems.map((item, index) => (
            <li
              className={
                highlightedIndex === index ? 'combobox__menu-item highlight' : 'combobox__menu-item'
              }
              key={`${item}-${index}`}
              {...getItemProps({item, index})}
            >
              {item}
            </li>
          ))}
      </ul>
    </div>
  )
}

DropdownCombobox.propTypes = {
  listItems: PropTypes.array,
  label: PropTypes.string,
  name: PropTypes.string
}

DropdownCombobox.defaultProps = {
  listItems: [],
  label: 'Select'
}

export default DropdownCombobox;
