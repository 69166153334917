import React from 'react';
import DisclaimerCheckbox from './disclaimer-checkbox';
import { useWizard } from './context/context';

const Disclaimer = () => {
  const { hasSubmitted } = useWizard();

  return (
    <>
      {!hasSubmitted &&
        <div className="disclaimer">
          <p className="small">
            Data provided is purely for relative valuations of chemical reactivity and for educational purposes only. Actual performance should be verified experimentally by qualified laboratories.
          </p>
          <p className="small">
            Limitation of liability. To the maximum extent permitted by law, RASIRC shall not be liable for any special, incidental, indirect, punitive, or consequential damages arising out of or in connection with use of this calculator and any information derived by it. (including without limitation damages for lost business or profits, loss of data or costs of procurement of substitute goods or services), even if advised of the possibility thereof.
          </p>
          <DisclaimerCheckbox
            label="I acknowledge and accept the above limitation of liability"
            name="lol-disclaimer"
          />
        </div>
      }
    </>
  );
};

export default Disclaimer;
