import React, {useRef, useState, useLayoutEffect} from 'react';
import useResizeObserver from '@react-hook/resize-observer'
import makeMolecule from './utilities/makeMolecule';
import { isBlank, formulaFormat } from './utilities/utilities';
import { useWizard } from './context/context';
import data from '../data/json/data';

// just a gray square, because reasons
const dummyImg = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAZQAAAB9CAMAAACyJ2VsAAAAA1BMVEX///+nxBvIAAAASElEQVR4nO3BMQEAAADCoPVPbQdvoAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABOA8XBAAGwGMP0AAAAAElFTkSuQmCC';


const useSize = (target) => {
  const [size, setSize] = useState();

  useLayoutEffect(() => {
    setSize(target.current.getBoundingClientRect())
  }, [target]);

  useResizeObserver(target, (entry) => setSize(entry.contentRect));

  return size;
}

const MoleculeAnimation = React.memo(({ metal, ligand, hasValues }) => {
  return (
    <>
      {makeMolecule(
        `${data[metal.toLowerCase()][ligand.toLowerCase()]?.pdbFilePath}`,
        hasValues
      )}
    </>
  );
});


const Molecule = () => {
  const { metal, ligand } = useWizard();
  const hasValues = isBlank(metal) || isBlank(ligand) ? false : true;
  const target = useRef(null);
  const size = useSize(target);

  return (
    <div className="molecule">
      {hasValues && <>
          <h2 className="molecule__head alloy-graph__formula" dangerouslySetInnerHTML={{ __html: formulaFormat(data[metal.toLowerCase()][ligand.toLowerCase()].formula)}} />
          <span className="molecule__sub-head">
            Precursor
          </span>
      </>}
      <div
        className="molecule__inner"
        ref={target}
      >
        {size && size?.width > 0 && hasValues &&
          <MoleculeAnimation
            hasValues={hasValues}
            metal={metal}
            ligand={ligand}
          />
        }
        {size && size?.width > 0 && !hasValues && makeMolecule('', hasValues)}
      </div>
      <img src={dummyImg} alt="" className="molecule__img" />
    </div>
  );
};

export default Molecule;
