import React from 'react';
import PropTypes from 'prop-types';
import { useWizard } from './context/context';

const DisclaimerCheckbox = (props) => {
  const {
    label,
    name
  } = props;

  const { acceptedDisclaimer, setDisclaimer } = useWizard();

  return (
    <div className="input input--checkbox">
      <input
        id={name}
        name={name}
        onChange={(e) => {
            setDisclaimer(e.target.checked);
            e.target.blur();
          }
        }
        type="checkbox"
        checked={acceptedDisclaimer}
      />
      <label htmlFor={name} className="input__label">{ label }</label>
    </div>
  );
};

DisclaimerCheckbox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string
};

export default DisclaimerCheckbox;
