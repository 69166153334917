import PropTypes from 'prop-types';
import React from 'react';
import { useWizard } from './context/context';

const Intro = ({ heading, body }) => {
  const { hasSubmitted } = useWizard();

  return (
    <>
      {!hasSubmitted &&
        <div className="intro">
          <h2 className="intro__heading" dangerouslySetInnerHTML={{ __html: heading }} />
          <div className="intro__body" dangerouslySetInnerHTML={{ __html: body }} />
        </div>
      }
    </>
  )
};

Intro.propTypes = {
  heading: PropTypes.string,
  body: PropTypes.string,
  children: PropTypes.node
};

Intro.defaultProps = {
  heading: 'RASIRC ALD Oxide Wizard <sup>&reg;</sup>',
  body: `<p>Welcome to the RASIRC ALD Oxide Wizard. The wizard compares the reactivity of water and hydrogen peroxide for 285 different precursors. Starting with the five of the most common metals used in Atomic Layer Deposition (ALD) and 57 common ligands, the wizard creates the desired precursor, visualizes the precursor molecule, and concludes with a determination of reaction energetics (favorable/less favorable) in terms of intrinsic energy changes ∆E added or released in the creation of the metal oxide with H<sub>2</sub>O<sub>2</sub> or water. The wizard allows you to make modifications to the ligand and see the effects on reactivity. Edit your design as many times as you want; when you have your desired reaction, choose the print option.</p>`
};

export default Intro;
