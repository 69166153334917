const data = {
  aluminum: {
    acetato: {
      formula: 'C6H9Al1O6',
      h2o: 16.6,
      h202: -108.1,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_1.pdb'
    },
    acetylacetonato: {
      formula: 'C15H21Al1O6',
      h2o: 48.9,
      h202: -49.1,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_2.pdb'
    },
    allyl: {
      formula: 'C9H15Al1',
      h2o: -163.4,
      h202: -237.7,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_3.pdb'
    },
    amido: {
      formula: 'H6Al1N3',
      h2o: -80.8,
      h202: -170.6,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_4.pdb'
    },
    aminopentenonato: {
      formula: 'C15H24Al1N3O3',
      h2o: 48.4,
      h202: -73.6,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_5.pdb'
    },
    bistrimethylsilylamido: {
      formula: 'C18H54Al1N3Si6',
      h2o: -87.5,
      h202: -203.2,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_6.pdb'
    },
    bromo: {
      formula: 'Al1Br3',
      h2o: 3.9,
      h202: -113.3,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_7.pdb'
    },
    chloro: {
      formula: 'Al1Cl3',
      h2o: -3,
      h202: -75.7,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_8.pdb'
    },
    cyano: {
      formula: 'C3Al1N3',
      h2o: -99.8,
      h202: -244.9,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_9.pdb'
    },
    cyclopentadienyl: {
      formula: 'C15H15Al1',
      h2o: -117.3,
      h202: -253.1,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_10.pdb'
    },
    diethylacetamidinato: {
      formula: 'C18H39Al1N6',
      h2o: -3.5,
      h202: -89,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_11.pdb'
    },
    diethylamido: {
      formula: 'C12H30Al1N3',
      h2o: -94.6,
      h202: -211.6,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_12.pdb'
    },
    diethyldithiocarbamato: {
      formula: 'C20H40Hf1N4S8',
      h2o: 9.5,
      h202: -133,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_13.pdb'
    },
    diisopropylacetamidinato: {
      formula: 'C24H51Al1N6',
      h2o: -8.2,
      h202: -137.2,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_14.pdb'
    },
    diisopropylamido: {
      formula: 'C18H42Al1N3',
      h2o: -116.3,
      h202: -235.1,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_15.pdb'
    },
    dimethylacetamidinato: {
      formula: 'C12H27Al1N6',
      h2o: -11.2,
      h202: -151.7,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_16.pdb'
    },
    dimethylamido: {
      formula: 'C6H18Al1N3',
      h2o: -87.9,
      h202: -196,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_17.pdb'
    },
    dimethylaminoethoxy: {
      formula: 'C12H30Al1N3O3',
      h2o: 5.6,
      h202: -109.6,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_18.pdb'
    },
    dimethylaminomethylbutoxy: {
      formula: 'C21H48Al1N3O3',
      h2o: 5.2,
      h202: -121.3,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_19.pdb'
    },
    dimethylaminomethylpropoxy: {
      formula: 'C18H42Al1N3O3',
      h2o: -6.3,
      h202: -117.2,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_20.pdb'
    },
    dimethylaminopropoxy: {
      formula: 'C15H36Al1N3O3',
      h2o: 4.5,
      h202: -116.7,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_21.pdb'
    },
    dimethylglyoximato: {
      formula: 'C12H21Al1N6O6',
      h2o: -9.6,
      h202: -44.8,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_22.pdb'
    },
    ditertbutylacetamidinato: {
      formula: 'C30H63Al1N6',
      h2o: -85.8,
      h202: -222.7,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_23.pdb'
    },
    ditertbutylamido: {
      formula: 'C24H54Al1N3',
      h2o: -136.3,
      h202: -253.6,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_24.pdb'
    },
    ethoxy: {
      formula: 'C6H15Al1O3',
      h2o: -33.4,
      h202: -158.3,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_25.pdb'
    },
    ethyl: {
      formula: 'C6H15Al1',
      h2o: -182.3,
      h202: -269.4,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_26.pdb'
    },
    ethylmethylamido: {
      formula: 'C9H24Al1N3',
      h2o: -125.3,
      h202: -236.4,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_27.pdb'
    },
    fluoro: {
      formula: 'Al1F3',
      h2o: 16,
      h202: 116.1,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_28.pdb'
    },
    formato: {
      formula: 'C3H3Al1O6',
      h2o: 8.7,
      h202: -153.1,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_29.pdb'
    },
    heptafluorodimethyloctanedionato: {
      formula: 'C30H30Al1F21O6',
      h2o: 66.4,
      h202: -42,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_30.pdb'
    },
    hexafluoroacetylacetonato: {
      formula: 'C15H3Al1F18O6',
      h2o: 61.3,
      h202: -29.2,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_31.pdb'
    },
    hydrido: {
      formula: 'H3Al1',
      h2o: -141.4,
      h202: -312.7,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_32.pdb'
    },
    imidazolyl: {
      formula: 'C9H9Al1N6',
      h2o: -83.8,
      h202: -217.7,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_33.pdb'
    },
    iodo: {
      formula: 'Al1I3',
      h2o: -8.7,
      h202: -158.9,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_34.pdb'
    },
    isocyanato: {
      formula: 'C3Al1N3O3',
      h2o: -19.4,
      h202: 80.5,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_35.pdb'
    },
    isopropoxy: {
      formula: 'C9H21Al1O3',
      h2o: -33.9,
      h202: -166.5,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_36.pdb'
    },
    isopropyl: {
      formula: 'C9H21Al1',
      h2o: -190.5,
      h202: -284,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_37.pdb'
    },
    isothiocyanato: {
      formula: 'C3Al1N3S3',
      h2o: -17.3,
      h202: -91.5,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_38.pdb'
    },
    methoxy: {
      formula: 'C3H9Al1O3',
      h2o: -37.2,
      h202: -149.1,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_39.pdb'
    },
    methoxymethylpropoxy: {
      formula: 'C15H33Al1O6',
      h2o: -4.1,
      h202: -146.3,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_40.pdb'
    },
    methyl: {
      formula: 'C3H9Al1',
      h2o: -172.8,
      h202: -282.2,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_41.pdb'
    },
    methylcyclopentadienyl: {
      formula: 'C18H21Al1',
      h2o: -112,
      h202: -286.4,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_42.pdb'
    },
    nitrato: {
      formula: 'Al1N3O9',
      h2o: 20.4,
      h202: -6.4,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_43.pdb'
    },
    nitrito: {
      formula: 'Al1N3O6',
      h2o: -4.5,
      h202: -49.2,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_44.pdb'
    },
    nitrosyl: {
      formula: 'Al1N3O3',
      h2o: -253.2,
      h202: -380.5,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_45.pdb'
    },
    pentamethylcyclopentadienyl: {
      formula: 'C30H45Al1',
      h2o: -140.3,
      h202: -305.7,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_46.pdb'
    },
    pyrazolate: {
      formula: 'C9H9Al1N6',
      h2o: -64.2,
      h202: -219.8,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_47.pdb'
    },
    pyrrolyl: {
      formula: 'C12H12Al1N3',
      h2o: -81.3,
      h202: -235.1,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_48.pdb'
    },
    tertbutoxy: {
      formula: 'C12H27Al1O3',
      h2o: -30.1,
      h202: -141.8,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_49.pdb'
    },
    tertbutyl: {
      formula: 'C12H27Al1',
      h2o: -197.5,
      h202: -295.6,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_50.pdb'
    },
    tetramethylguanidinato: {
      formula: 'C15H36Al1N9',
      h2o: -7,
      h202: -152,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_51.pdb'
    },
    tetramethylheptanedionato: {
      formula: 'C33H57Al1O6',
      h2o: 58,
      h202: -49,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_52.pdb'
    },
    tetramethylphospaguanidinato: {
      formula: 'C15H36Al1N6P3',
      h2o: -7.7,
      h202: -156,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_53.pdb'
    },
    thioacetylacetonato: {
      formula: 'C15H21Al1S6',
      h2o: -10.5,
      h202: -148.1,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_54.pdb'
    },
    triflato: {
      formula: 'C3Al1F9O9S3',
      h2o: 27.6,
      h202: -0.2,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_55.pdb'
    },
    trifluoroacetylacetonato: {
      formula: 'C15H12Al1F9O6',
      h2o: 59.5,
      h202: -34.6,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_56.pdb'
    },
    trimethylisoureato: {
      formula: 'C12H27Al1N6O3',
      h2o: -14.5,
      h202: -143.3,
      nh3: '',
      n2h4: '',
      targetOxide: 'Al2O3',
      pdbFilePath: 'data/pdb/al/rasirc_57.pdb'
    }
  },
  silicon: {
    acetato: {
      formula: 'C8H12O8Si1',
      h2o: -21.3,
      h202: -146,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_58.pdb'
    },
    acetylacetonato: {
      formula: 'C20H28O8Si1',
      h2o: -36.9,
      h202: -134.9,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_59.pdb'
    },
    allyl: {
      formula: 'C12H20Si1',
      h2o: -103.7,
      h202: -178,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_60.pdb'
    },
    amido: {
      formula: 'H8N4Si1',
      h2o: -49.9,
      h202: -139.7,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_61.pdb'
    },
    aminopentenonato: {
      formula: 'C20H32N4O4Si1',
      h2o: -14,
      h202: -136,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_62.pdb'
    },
    bistrimethylsilylamido: {
      formula: 'C24H72N4Si9',
      h2o: -147.6,
      h202: -263.2,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_63.pdb'
    },
    bromo: {
      formula: 'Br4Si1',
      h2o: -14.8,
      h202: -132,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_64.pdb'
    },
    chloro: {
      formula: 'Cl4Si1',
      h2o: -11.9,
      h202: -84.6,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_65.pdb'
    },
    cyano: {
      formula: 'C4N4Si1',
      h2o: -77.3,
      h202: -222.4,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_66.pdb'
    },
    cyclopentadienyl: {
      formula: 'C20H20Si1',
      h2o: -82.8,
      h202: -218.5,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_67.pdb'
    },
    diethylacetamidinato: {
      formula: 'C24H52N8Si1',
      h2o: -90.5,
      h202: -176,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_68.pdb'
    },
    diethylamido: {
      formula: 'C16H40N4Si1',
      h2o: -54.8,
      h202: -171.8,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_69.pdb'
    },
    diethyldithiocarbamato: {
      formula: 'C20H40Hf1N4S8',
      h2o: -38.8,
      h202: -181.4,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_70.pdb'
    },
    diisopropylacetamidinato: {
      formula: 'C32H68N8Si1',
      h2o: -176.2,
      h202: -305.2,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_71.pdb'
    },
    diisopropylamido: {
      formula: 'C24H56N4Si1',
      h2o: -142.2,
      h202: -260.9,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_72.pdb'
    },
    dimethylacetamidinato: {
      formula: 'C16H36N8Si1',
      h2o: -49.7,
      h202: -190.3,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_73.pdb'
    },
    dimethylamido: {
      formula: 'C8H24N4Si1',
      h2o: -49,
      h202: -157.2,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_74.pdb'
    },
    dimethylaminoethoxy: {
      formula: 'C16H40N4O4Si1',
      h2o: -0.4,
      h202: -115.6,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_75.pdb'
    },
    dimethylaminomethylbutoxy: {
      formula: 'C28H64N4O4Si1',
      h2o: -48.6,
      h202: -175,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_76.pdb'
    },
    dimethylaminomethylpropoxy: {
      formula: 'C24H56N4O4Si1',
      h2o: -39.8,
      h202: -150.7,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_77.pdb'
    },
    dimethylaminopropoxy: {
      formula: 'C20H48N4O4Si1',
      h2o: -0.2,
      h202: -121.5,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_78.pdb'
    },
    dimethylglyoximato: {
      formula: 'C16H28N8O8Si1',
      h2o: -98.6,
      h202: -133.8,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_79.pdb'
    },
    ditertbutylacetamidinato: {
      formula: 'C40H84N8Si1',
      h2o: -175.5,
      h202: -312.4,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_80.pdb'
    },
    ditertbutylamido: {
      formula: 'C32H72N4Si1',
      h2o: -316.6,
      h202: -433.9,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_81.pdb'
    },
    ethoxy: {
      formula: 'C8H20O4Si1',
      h2o: 1.5,
      h202: -123.4,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_82.pdb'
    },
    ethyl: {
      formula: 'C8H20Si1',
      h2o: -112.4,
      h202: -199.5,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_83.pdb'
    },
    ethylmethylamido: {
      formula: 'C12H32N4Si1',
      h2o: -47.8,
      h202: -158.9,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_84.pdb'
    },
    fluoro: {
      formula: 'F4Si1',
      h2o: 33.5,
      h202: 133.6,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_85.pdb'
    },
    formato: {
      formula: 'C4H4O8Si1',
      h2o: -25.6,
      h202: -187.4,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_86.pdb'
    },
    heptafluorodimethyloctanedionato: {
      formula: 'C40H40F28O8Si1',
      h2o: -99.6,
      h202: -208,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_87.pdb'
    },
    hexafluoroacetylacetonato: {
      formula: 'C20H4F24O8Si1',
      h2o: -29.4,
      h202: -119.9,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_88.pdb'
    },
    hydrido: {
      formula: 'H4Si1',
      h2o: -73.6,
      h202: -244.9,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_89.pdb'
    },
    imidazolyl: {
      formula: 'C12H12N8Si1',
      h2o: -60.8,
      h202: -194.8,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_90.pdb'
    },
    iodo: {
      formula: 'I4Si1',
      h2o: -25.3,
      h202: -175.5,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_91.pdb'
    },
    isocyanato: {
      formula: 'C4N4O4Si1',
      h2o: -5.6,
      h202: 94.3,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_92.pdb'
    },
    isopropoxy: {
      formula: 'C12H28O4Si1',
      h2o: 3.5,
      h202: -129.1,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_93.pdb'
    },
    isopropyl: {
      formula: 'C12H28Si1',
      h2o: -119.1,
      h202: -212.6,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_94.pdb'
    },
    isothiocyanato: {
      formula: 'C4N4S4Si1',
      h2o: -2.2,
      h202: -76.4,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_95.pdb'
    },
    methoxy: {
      formula: 'C4H12O4Si1',
      h2o: 0.6,
      h202: -111.3,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_96.pdb'
    },
    methoxymethylpropoxy: {
      formula: 'C20H44O8Si1',
      h2o: 3.2,
      h202: -139.1,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_97.pdb'
    },
    methyl: {
      formula: 'C4H12Si1',
      h2o: -108.6,
      h202: -218,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_98.pdb'
    },
    methylcyclopentadienyl: {
      formula: 'C24H28Si1',
      h2o: -82.3,
      h202: -256.8,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_99.pdb'
    },
    nitrato: {
      formula: 'N4O12Si1',
      h2o: -38.9,
      h202: -65.6,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_100.pdb'
    },
    nitrito: {
      formula: 'N4O8Si1',
      h2o: -38,
      h202: -82.7,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_101.pdb'
    },
    nitrosyl: {
      formula: 'N4O4Si1',
      h2o: -205.7,
      h202: -333,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_102.pdb'
    },
    pentamethylcyclopentadienyl: {
      formula: 'C40H60Si1',
      h2o: -201,
      h202: -366.4,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_103.pdb'
    },
    pyrazolate: {
      formula: 'C12H12N8Si1',
      h2o: -47.3,
      h202: -202.9,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_104.pdb'
    },
    pyrrolyl: {
      formula: 'C16H16N4Si1',
      h2o: -44.3,
      h202: -198.1,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_105.pdb'
    },
    tertbutoxy: {
      formula: 'C16H36O4Si1',
      h2o: 2.7,
      h202: -109,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_106.pdb'
    },
    tertbutyl: {
      formula: 'C16H36Si1',
      h2o: -138.3,
      h202: -236.4,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_107.pdb'
    },
    tetramethylguanidinato: {
      formula: 'C20H48N12Si1',
      h2o: -98,
      h202: -242.9,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_108.pdb'
    },
    tetramethylheptanedionato: {
      formula: 'C44H76O8Si1',
      h2o: -88.3,
      h202: -195.4,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_109.pdb'
    },
    tetramethylphospaguanidinato: {
      formula: 'C20H48N8P4Si1',
      h2o: -84,
      h202: -232.3,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_110.pdb'
    },
    thioacetylacetonato: {
      formula: 'C20H28S8Si1',
      h2o: -77.9,
      h202: -215.5,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_111.pdb'
    },
    triflato: {
      formula: 'C4F12O12S4Si1',
      h2o: -16.6,
      h202: -44.4,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_112.pdb'
    },
    trifluoroacetylacetonato: {
      formula: 'C20H16F12O8Si1',
      h2o: -27.3,
      h202: -121.4,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_113.pdb'
    },
    trimethylisoureato: {
      formula: 'C16H36N8O4Si1',
      h2o: -40.2,
      h202: -168.9,
      nh3: '',
      n2h4: '',
      targetOxide: 'SiO2',
      pdbFilePath: 'data/pdb/si/rasirc_114.pdb'
    },
  },
  titanium: {
    acetato: {
      formula: 'C8H12O8Ti1',
      h2o: -22.7,
      h202: -147.4,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_115.pdb'
    },
    acetylacetonato: {
      formula: 'C20H28O8Ti1',
      h2o: -17.4,
      h202: -115.4,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_116.pdb'
    },
    allyl: {
      formula: 'C12H20Ti1',
      h2o: -163,
      h202: -237.3,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_117.pdb'
    },
    amido: {
      formula: 'H8N4Ti1',
      h2o: -81.1,
      h202: -171,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_118.pdb'
    },
    aminopentenonato: {
      formula: 'C20H32N4O4Ti1',
      h2o: -13.1,
      h202: -135.1,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_119.pdb'
    },
    bistrimethylsilylamido: {
      formula: 'C24H72N4Si8Ti1',
      h2o: -110.3,
      h202: -226,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_120.pdb'
    },
    bromo: {
      formula: 'Br4Ti1',
      h2o: -4.8,
      h202: -122,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_121.pdb'
    },
    chloro: {
      formula: 'Cl4Ti1',
      h2o: -7.7,
      h202: -80.4,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_122.pdb'
    },
    cyano: {
      formula: 'C4N4Ti1',
      h2o: -138.4,
      h202: -283.4,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_123.pdb'
    },
    cyclopentadienyl: {
      formula: 'C20H20Ti1',
      h2o: -78.5,
      h202: -214.3,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_124.pdb'
    },
    diethylacetamidinato: {
      formula: 'C24H52N8Ti1',
      h2o: -94,
      h202: -179.5,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_125.pdb'
    },
    diethylamido: {
      formula: 'C16H40N4Ti1',
      h2o: -70.3,
      h202: -187.3,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_126.pdb'
    },
    diethyldithiocarbamato: {
      formula: 'C20H40N4S8Ti1',
      h2o: -94.1,
      h202: -236.7,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_127.pdb'
    },
    diisopropylacetamidinato: {
      formula: 'C32H68N8Ti1',
      h2o: -137.6,
      h202: -266.6,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_128.pdb'
    },
    diisopropylamido: {
      formula: 'C24H56N4Ti1',
      h2o: -154.3,
      h202: -273.1,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_129.pdb'
    },
    dimethylacetamidinato: {
      formula: 'C16H36N8Ti1',
      h2o: -84.5,
      h202: -225.1,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_130.pdb'
    },
    dimethylamido: {
      formula: 'C8H24N4Ti1',
      h2o: -66.6,
      h202: -174.7,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_131.pdb'
    },
    dimethylaminoethoxy: {
      formula: 'C16H40N4O4Ti1',
      h2o: 2.3,
      h202: -112.8,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_132.pdb'
    },
    dimethylaminomethylbutoxy: {
      formula: 'C28H64N4O4Ti1',
      h2o: -72.8,
      h202: -199.2,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_133.pdb'
    },
    dimethylaminomethylpropoxy: {
      formula: 'C24H56N4O4Ti1',
      h2o: -58.6,
      h202: -169.5,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_134.pdb'
    },
    dimethylaminopropoxy: {
      formula: 'C20H48N4O4Ti1',
      h2o: -45.6,
      h202: -166.9,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_135.pdb'
    },
    dimethylglyoximato: {
      formula: 'C16H28N8O8Ti1',
      h2o: -35.2,
      h202: -70.4,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_136.pdb'
    },
    ditertbutylacetamidinato: {
      formula: 'C40H84N8Ti1',
      h2o: -228,
      h202: -364.9,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_137.pdb'
    },
    ditertbutylamido: {
      formula: 'C32H72N4Ti1',
      h2o: -273.9,
      h202: -391.2,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_138.pdb'
    },
    ethoxy: {
      formula: 'C8H20O4Ti1',
      h2o: -7,
      h202: -131.9,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_139.pdb'
    },
    ethyl: {
      formula: 'C8H20Ti1',
      h2o: -193.4,
      h202: -280.5,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_140.pdb'
    },
    ethylmethylamido: {
      formula: 'C12H32N4Ti1',
      h2o: -64.7,
      h202: -175.9,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_141.pdb'
    },
    fluoro: {
      formula: 'F4Ti1',
      h2o: 23.2,
      h202: 123.4,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_142.pdb'
    },
    formato: {
      formula: 'C4H4O8Ti1',
      h2o: -33.7,
      h202: -195.6,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_143.pdb'
    },
    heptafluorodimethyloctanedionato: {
      formula: 'C40H40F28O8Ti1',
      h2o: -95.1,
      h202: -203.5,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_144.pdb'
    },
    hexafluoroacetylacetonato: {
      formula: 'C20H4F24O8Ti1',
      h2o: 2,
      h202:-88.5 ,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_145.pdb'
    },
    hydrido: {
      formula: 'H4Ti1',
      h2o: -192,
      h202: -363.3,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_146.pdb'
    },
    imidazolyl: {
      formula: 'C12H12N8Ti1',
      h2o: -84.7,
      h202: -218.6,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_147.pdb'
    },
    iodo: {
      formula: 'I4Ti1',
      h2o: -18.1,
      h202: -168.2,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_148.pdb'
    },
    isocyanato: {
      formula: 'C4N4O4Ti1',
      h2o: -20.5,
      h202: 79.4,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_149.pdb'
    },
    isopropoxy: {
      formula: 'C12H28O4Ti1',
      h2o: -4.8,
      h202: -137.4,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_150.pdb'
    },
    isopropyl: {
      formula: 'C12H28Ti1',
      h2o: -191.6,
      h202: -285.1,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_151.pdb'
    },
    isothiocyanato: {
      formula: 'C4N4S4Ti1',
      h2o: -9.6,
      h202: -83.8,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_152.pdb'
    },
    methoxy: {
      formula: 'C4H12O4Ti1',
      h2o: -10.2,
      h202: -122,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_153.pdb'
    },
    methoxymethylpropoxy: {
      formula: 'C20H44O8Ti1',
      h2o: -46.6,
      h202: -188.9,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_154.pdb'
    },
    methyl: {
      formula: 'C4H12Ti1',
      h2o: -192.3,
      h202: -301.7,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_155.pdb'
    },
    methylcyclopentadienyl: {
      formula: 'C24H28Ti1',
      h2o: -83.9,
      h202: -258.4,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_156.pdb'
    },
    nitrato: {
      formula: 'N4O12Ti1',
      h2o: -12.4,
      h202: -39.2,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_157.pdb'
    },
    nitrito: {
      formula: 'N4O8Ti1',
      h2o: -46.6,
      h202: -91.4,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_158.pdb'
    },
    nitrosyl: {
      formula: 'N4O4Ti1',
      h2o: -121.9,
      h202: -249.2,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_159.pdb'
    },
    pentamethylcyclopentadienyl: {
      formula: 'C40H60Ti1',
      h2o: -226,
      h202: -391.3,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_160.pdb'
    },
    pyrazolate: {
      formula: 'C12H12N8Ti1',
      h2o: -55.9,
      h202: -211.5,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_161.pdb'
    },
    pyrrolyl: {
      formula: 'C16H16N4Ti1',
      h2o: -79.3,
      h202: -233,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_162.pdb'
    },
    tertbutoxy: {
      formula: 'C16H36O4Ti1',
      h2o: -1.2,
      h202: -113,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_163.pdb'
    },
    tertbutyl: {
      formula: 'C16H36Ti1',
      h2o: -194.4,
      h202: -292.5,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_164.pdb'
    },
    tetramethylguanidinato: {
      formula: 'C20H48N12Ti1',
      h2o: -94.6,
      h202: -239.6,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_165.pdb'
    },
    tetramethylheptanedionato: {
      formula: 'C44H76O8Ti1',
      h2o: -77.1,
      h202: -184.2,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_166.pdb'
    },
    tetramethylphospaguanidinato: {
      formula: 'C20H48N8P4Ti1',
      h2o: -84.3,
      h202: -232.6,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_167.pdb'
    },
    thioacetylacetonato: {
      formula: 'C20H28S8Ti1',
      h2o: -35.1,
      h202: -172.7,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_168.pdb'
    },
    triflato: {
      formula: 'C4F12O12S4Ti1',
      h2o: -6.8,
      h202: -34.6,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_169.pdb'
    },
    trifluoroacetylacetonato: {
      formula: 'C20H16F12O8Ti1',
      h2o: -3.1,
      h202: -97.2,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_170.pdb'
    },
    trimethylisoureato: {
      formula: 'C16H36N8O4Ti1',
      h2o: -91,
      h202: -219.8,
      nh3: '',
      n2h4: '',
      targetOxide: 'TiO2',
      pdbFilePath: 'data/pdb/ti/rasirc_171.pdb'
    }
  },
  zirconium: {
    acetato: {
      formula: 'C8H12O8Zr1',
          h2o: -11,
          h202: -135.7,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_172.pdb'
    },
    acetylacetonato: {
      formula: 'C20H28O8Zr1',
          h2o: 8.8,
          h202: -89.2,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_173.pdb'
    },
    allyl: {
      formula: 'C12H20Zr1',
          h2o: -173.3,
          h202: -247.6,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_174.pdb'
    },
    amido: {
      formula: 'H8N4Zr1',
          h2o: -105.9,
          h202: -195.8,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_175.pdb'
    },
    aminopentenonato: {
      formula: 'C20H32N4O4Zr1',
          h2o: 10.3,
          h202: -111.6,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_176.pdb'
    },
    bistrimethylsilylamido: {
      formula: 'C24H72N4Si8Zr1',
          h2o: -101.9,
          h202: -217.6,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_177.pdb'
    },
    bromo: {
      formula: 'Br4Zr1',
          h2o: -3.2,
          h202: -120.4,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_178.pdb'
    },
    chloro: {
      formula: 'Cl4Zr1',
          h2o: -10.6,
          h202: -83.3,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_179.pdb'
    },
    cyano: {
      formula: 'C4N4Zr1',
          h2o: -145.2,
          h202: -290.3,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_180.pdb'
    },
    cyclopentadienyl: {
      formula: 'C20H20Zr1',
          h2o: -84.4,
          h202: -220.2,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_181.pdb'
    },
    diethylacetamidinato: {
      formula: 'C24H52N8Zr1',
          h2o: -26,
          h202: -111.4,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_182.pdb'
    },
    diethylamido: {
      formula: 'C16H40N4Zr1',
          h2o: -102.6,
          h202: -219.6,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_183.pdb'
    },
    diethyldithiocarbamato: {
      formula: 'C20H40Hf1N4S8',
          h2o: 23.1,
          h202: -119.5,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_184.pdb'
    },
    diisopropylacetamidinato: {
      formula: 'C32H68N8Zr1',
          h2o: -46.9,
          h202: -175.9,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_185.pdb'
    },
    diisopropylamido: {
      formula: 'C24H56N4Zr1',
          h2o: -155.1,
          h202: -273.9,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_186.pdb'
    },
    dimethylacetamidinato: {
      formula: 'C16H36N8Zr1',
          h2o: -31.7,
          h202: -172.2,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_187.pdb'
    },
    dimethylamido: {
      formula: 'C8H24N4Zr1',
          h2o: -100,
          h202: -208.1,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_188.pdb'
    },
    dimethylaminoethoxy: {
      formula: 'C16H40N4O4Zr1',
          h2o: -9.1,
          h202: -124.2,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_189.pdb'
    },
    dimethylaminomethylbutoxy: {
      formula: 'C28H64N4O4Zr1',
          h2o: -58.9,
          h202: -185.3,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_190.pdb'
    },
    dimethylaminomethylpropoxy: {
      formula: 'C24H56N4O4Zr1',
          h2o: -41.8,
          h202: -152.7,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_191.pdb'
    },
    dimethylaminopropoxy: {
      formula: 'C20H48N4O4Zr1',
          h2o: -9.1,
          h202: -130.4,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_192.pdb'
    },
    dimethylglyoximato: {
      formula: 'C16H28N8O8Zr1',
          h2o: -36.8,
          h202: -71.9,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_193.pdb'
    },
    ditertbutylacetamidinato: {
      formula: 'C40H84N8Zr1',
          h2o: -183.2,
          h202: -320.1,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_194.pdb'
    },
    ditertbutylamido: {
      formula: 'C32H72N4Zr1',
          h2o: -249.1,
          h202: -366.4,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_195.pdb'
    },
    ethoxy: {
      formula: 'C8H20O4Zr1',
          h2o: -30.1,
          h202: -155,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_196.pdb'
    },
    ethyl: {
      formula: 'C8H20Zr1',
          h2o: -223.7,
          h202: -310.7,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_197.pdb'
    },
    ethylmethylamido: {
      formula: 'C12H32N4Zr1',
          h2o: -96.7,
          h202: -207.9,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_198.pdb'
    },
    fluoro: {
      formula: 'F4Zr1',
          h2o: 10.5,
          h202: 110.7,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_199.pdb'
    },
    formato: {
      formula: 'C4H4O8Zr1',
          h2o: -21.2,
          h202: -183,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_200.pdb'
    },
    heptafluorodimethyloctanedionato: {
      formula: 'C40H40F28O8Zr1',
          h2o: -46.2,
          h202: -154.5,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_201.pdb'
    },
    hexafluoroacetylacetonato: {
      formula: 'C20H4F24O8Zr1',
          h2o: 25.5,
          h202: -65,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_202.pdb'
    },
    hydrido: {
      formula: 'H4Zr1',
          h2o: -203.3,
          h202: -374.6,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_203.pdb'
    },
    imidazolyl: {
      formula: 'C12H12N8Zr1',
          h2o: -109,
          h202: -242.9,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_204.pdb'
    },
    iodo: {
      formula: 'I4Zr1',
          h2o: -14.1,
          h202: -164.2,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_205.pdb'
    },
    isocyanato: {
      formula: 'C4N4O4Zr1',
          h2o: -33.2,
          h202: 66.7,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_206.pdb'
    },
    isopropoxy: {
      formula: 'C12H28O4Zr1',
          h2o: -28,
          h202: -160.6,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_207.pdb'
    },
    isopropyl: {
      formula: 'C12H28Zr1',
          h2o: -225.8,
          h202: -319.3,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_208.pdb'
    },
    isothiocyanato: {
      formula: 'C4N4S4Zr1',
          h2o: -25.6,
          h202: -99.8,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_209.pdb'
    },
    methoxy: {
      formula: 'C4H12O4Zr1',
          h2o: -31.1,
          h202: -142.9,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_210.pdb'
    },
    methoxymethylpropoxy: {
      formula: 'C20H44O8Zr1',
          h2o: 0.2,
          h202: -142.1,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_211.pdb'
    },
    methyl: {
      formula: 'C4H12Zr1',
          h2o: -217,
          h202: -326.5,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_212.pdb'
    },
    methylcyclopentadienyl: {
      formula: 'C24H28Zr1',
          h2o: -88,
          h202: -262.4,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_213.pdb'
    },
    nitrato: {
      formula: 'N4O12Zr1',
          h2o: -13.6,
          h202: -40.4,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_214.pdb'
    },
    nitrito: {
      formula: 'N4O8Zr1',
          h2o: -43.2,
          h202: -87.9,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_215.pdb'
    },
    nitrosyl: {
      formula: 'N4O4Zr1',
          h2o: -185.1,
          h202: -312.3,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_216.pdb'
    },
    pentamethylcyclopentadienyl: {
      formula: 'C40H60Zr1',
          h2o: -209.4,
          h202: -374.7,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_217.pdb'
    },
    pyrazolate: {
      formula: 'C12H12N8Zr1',
          h2o: -47.6,
          h202: -203.2,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_218.pdb'
    },
    pyrrolyl: {
      formula: 'C16H16N4Zr1',
          h2o: -104.7,
          h202: -258.5,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_219.pdb'
    },
    tertbutoxy: {
      formula: 'C16H36O4Zr1',
          h2o: -25.9,
          h202: -137.6,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_220.pdb'
    },
    tertbutyl: {
      formula: 'C16H36Zr1',
          h2o: -223.8,
          h202: -321.9,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_221.pdb'
    },
    tetramethylguanidinato: {
      formula: 'C20H48N12Zr1',
          h2o: -64.1,
          h202: -209.1,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_222.pdb'
    },
    tetramethylheptanedionato: {
      formula: 'C44H76O8Zr1',
          h2o: -32.5,
          h202: -139.5,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_223.pdb'
    },
    tetramethylphospaguanidinato: {
      formula: 'C20H48N8P4Zr1',
          h2o: -65,
          h202: -213.4,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_224.pdb'
    },
    thioacetylacetonato: {
      formula: 'C20H28S8Zr1',
          h2o: -24.6,
          h202: -162.2,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_225.pdb'
    },
    triflato: {
      formula: 'C4F12O12S4Zr1',
          h2o: 14.8,
          h202: -13,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_226.pdb'
    },
    trifluoroacetylacetonato: {
      formula: 'C20H16F12O8Zr1',
          h2o: 21,
          h202: -73.2,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_227.pdb'
    },
    trimethylisoureato: {
      formula: 'C16H36N8O4Zr1',
          h2o: -30.5,
          h202: -159.2,
          nh3: '',
          n2h4: '',
          targetOxide: 'ZrO2',
          pdbFilePath: 'data/pdb/zr/rasirc_228.pdb'
    },
  },
  hafnium: {
    acetato: {
          formula: 'C8H12Hf1O8',
          h2o: -10.9,
          h202: -135.6,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_229.pdb'
    },
    acetylacetonato: {
          formula: 'C20H28Hf1O8',
          h2o: 8.6,
          h202: -89.4,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_230.pdb'
    },
    allyl: {
          formula: 'C12H20Hf1',
          h2o: -195.3,
          h202: -269.5,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_231.pdb'
    },
    amido: {
          formula: 'H8Hf1N4',
          h2o: -107.9,
          h202: -197.7,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_232.pdb'
    },
    aminopentenonato: {
          formula: 'C20H32Hf1N4O4',
          h2o: 8.9,
          h202: -113.1,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_233.pdb'
    },
    bistrimethylsilylamido: {
          formula: 'C24H72Hf1N4Si8',
          h2o: -138.1,
          h202: -253.7,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_234.pdb'
    },
    bromo: {
          formula: 'Br4Hf1',
          h2o: -16.1,
          h202: -133.3,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_235.pdb'
    },
    chloro: {
          formula: 'Cl4Hf1',
          h2o: -16.6,
          h202: -89.3,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_236.pdb'
    },
    cyano: {
          formula: 'C4Hf1N4',
          h2o: -148.6,
          h202: -293.7,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_237.pdb'
    },
    cyclopentadienyl: {
          formula: 'C20H20Hf1',
          h2o: -101.4,
          h202: -237.2,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_238.pdb'
    },
    diethylacetamidinato: {
          formula: 'C24H52Hf1N8',
          h2o: -27.3,
          h202: -112.8,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_239.pdb'
    },
    diethylamido: {
          formula: 'C16H40Hf1N4',
          h2o: -107.1,
          h202: -224.1,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_240.pdb'
    },
    diethyldithiocarbamato: {
          formula: 'C20H40Hf1N4S8',
          h2o: 9.8,
          h202: -132.8,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_241.pdb'
    },
    diisopropylacetamidinato: {
          formula: 'C32H68Hf1N8',
          h2o: -101,
          h202: -230,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_242.pdb'
    },
    diisopropylamido: {
          formula: 'C24H56Hf1N4',
          h2o: -127.7,
          h202: -246.4,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_243.pdb'
    },
    dimethylacetamidinato: {
          formula: 'C16H36Hf1N8',
          h2o: -34.1,
          h202: -174.7,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_244.pdb'
    },
    dimethylamido: {
          formula: 'C8H24Hf1N4',
          h2o: -104.9,
          h202: -213,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_245.pdb'
    },
    dimethylaminoethoxy: {
          formula: 'C16H40Hf1N4O4',
          h2o: -5.5,
          h202: -120.7,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_246.pdb'
    },
    dimethylaminomethylbutoxy: {
          formula: 'C28H64Hf1N4O4',
          h2o: -58.6,
          h202: -185.1,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_247.pdb'
    },
    dimethylaminomethylpropoxy: {
          formula: 'C24H56Hf1N4O4',
          h2o: -4.3,
          h202: -115.2,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_248.pdb'
    },
    dimethylaminopropoxy: {
          formula: 'C20H48Hf1N4O4',
          h2o: -8.9,
          h202: -130.1,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_249.pdb'
    },
    dimethylglyoximato: {
          formula: 'C16H28Hf1N8O8',
          h2o: -45.3,
          h202: -80.5,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_250.pdb'
    },
    ditertbutylacetamidinato: {
          formula: 'C40H84Hf1N8',
          h2o: -124.9,
          h202: -261.8,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_251.pdb'
    },
    ditertbutylamido: {
          formula: 'C32H72Hf1N4',
          h2o: -259.4,
          h202: -376.7,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_252.pdb'
    },
    ethoxy: {
          formula: 'C8H20Hf1O4',
          h2o: -28.9,
          h202: -153.8,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_253.pdb'
    },
    ethyl: {
          formula: 'C8H20Hf1',
          h2o: -232.1,
          h202: -319.2,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_254.pdb'
    },
    ethylmethylamido: {
          formula: 'C12H32Hf1N4',
          h2o: -101.7,
          h202: -212.8,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_255.pdb'
    },
    fluoro: {
          formula: 'F4Hf1',
          h2o: 13.4,
          h202: 113.5,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_256.pdb'
    },
    formato: {
          formula: 'C4H4Hf1O8',
          h2o: -20,
          h202: -181.8,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_257.pdb'
    },
    heptafluorodimethyloctanedionato: {
          formula: 'C40H40F28Hf1O8',
          h2o: -49.5,
          h202: -157.8,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_258.pdb'
    },
    hexafluoroacetylacetonato: {
          formula: 'C20H4F24Hf1O8',
          h2o: 25.2,
          h202: -65.2,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_259.pdb'
    },
    hydrido: {
          formula: 'H4Hf1',
          h2o: -212.3,
          h202: -383.6,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_260.pdb'
    },
    imidazolyl: {
          formula: 'C12H12Hf1N8',
          h2o: -111.9,
          h202: -245.8,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_261.pdb'
    },
    iodo: {
          formula: 'Hf1I4',
          h2o: -30.3,
          h202: -180.5,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_262.pdb'
    },
    isocyanato: {
          formula: 'C4Hf1N4O4',
          h2o: -33.3,
          h202: 66.6,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_263.pdb'
    },
    isopropoxy: {
          formula: 'C12H28Hf1O4',
          h2o: -26.9,
          h202: -159.4,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_264.pdb'
    },
    isopropyl: {
          formula: 'C12H28Hf1',
          h2o: -235.6,
          h202: -329.1,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_265.pdb'
    },
    isothiocyanato: {
          formula: 'C4Hf1N4S4',
          h2o: -27.4,
          h202: -101.6,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_266.pdb'
    },
    methoxy: {
          formula: 'C4H12Hf1O4',
          h2o: -30.7,
          h202: -142.6,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_267.pdb'
    },
    methoxymethylpropoxy: {
          formula: 'C20H44Hf1O8',
          h2o: 0.2,
          h202: -142.1,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_268.pdb'
    },
    methyl: {
          formula: 'C4H12Hf1',
          h2o: -224.4,
          h202: -333.9,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_269.pdb'
    },
    methylcyclopentadienyl: {
          formula: 'C24H28Hf1',
          h2o: -105.8,
          h202: -280.2,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_270.pdb'
    },
    nitrato: {
          formula: 'Hf1N4O12',
          h2o: -15.2,
          h202: -42,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_271.pdb'
    },
    nitrito: {
          formula: 'Hf1N4O8',
          h2o: -44.8,
          h202: -89.5,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_272.pdb'
    },
    nitrosyl: {
          formula: 'Hf1N4O4',
          h2o: -205.5,
          h202: -332.8,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_273.pdb'
    },
    pentamethylcyclopentadienyl: {
          formula: 'C40H60Hf1',
          h2o: -227.5,
          h202: -392.8,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_274.pdb'
    },
    pyrazolate: {
          formula: 'C12H12Hf1N8',
          h2o: -51,
          h202: -206.6,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_275.pdb'
    },
    pyrrolyl: {
          formula: 'C16H16Hf1N4',
          h2o: -108.2,
          h202: -262,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_276.pdb'
    },
    tertbutoxy: {
          formula: 'C16H36Hf1O4',
          h2o: -24.5,
          h202: -136.2,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_277.pdb'
    },
    tertbutyl: {
          formula: 'C16H36Hf1',
          h2o: -234.8,
          h202: -332.8,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_278.pdb'
    },
    tetramethylguanidinato: {
          formula: 'C20H48Hf1N12',
          h2o: -23.4,
          h202: -168.4,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_279.pdb'
    },
    tetramethylheptanedionato: {
          formula: 'C44H76Hf1O8',
          h2o: 25.1,
          h202: -81.9,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_280.pdb'
    },
    tetramethylphospaguanidinato: {
          formula: 'C20H48Hf1N8P4',
          h2o: -65.3,
          h202: -213.6,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_281.pdb'
    },
    thioacetylacetonato: {
          formula: 'C20H28Hf1S8',
          h2o: -39.2,
          h202: -176.8,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_282.pdb'
    },
    triflato: {
          formula: 'C4F12Hf1O12S4',
          h2o: 13.5,
          h202: -14.3,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_283.pdb'
    },
    trifluoroacetylacetonato: {
          formula: 'C20H16F12Hf1O8',
          h2o: 21,
          h202: -73.1,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_284.pdb'
    },
    trimethylisoureato: {
          formula: 'C16H36Hf1N8O4',
          h2o: -28.5,
          h202: -157.2,
          nh3: '',
          n2h4: '',
          targetOxide: 'HfO2',
          pdbFilePath: 'data/pdb/hf/rasirc_285.pdb'
    },
  }
}

export default data;
