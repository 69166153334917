import * as THREE from "three";
import { PDBLoader } from "three/examples/jsm/loaders/PDBLoader.js";
import { trailingSlashIt } from './utilities';
import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js';
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls.js';

const makeMolecule = (path, shouldFire) => {
  let camera, scene, renderer, labelRenderer, controls, root;

  const rootElem = document.querySelector(".molecule__inner");
  const staticImg = document.querySelector('.molecule__img');
  const aspect = (rootElem.offsetWidth - 40);

  const loader = new PDBLoader();
  const offset = new THREE.Vector3();

  if (!shouldFire) {
    rootElem.innerHTML = '';
    return;
  }

  init();
  animate();

  function init() {
    scene = new THREE.Scene();
    scene.background = new THREE.Color(0x080e1c);

    camera = new THREE.PerspectiveCamera(
      70,
      aspect / aspect,
      1,
      5000
    );

    camera.position.z = 1000;
    scene.add(camera);

    const light1 = new THREE.DirectionalLight(0xffffff, 0.8);
    light1.position.set(1, 1, 1);
    scene.add(light1);

    const light2 = new THREE.DirectionalLight(0xffffff, 0.5);
    light2.position.set(-1, -1, 1);
    scene.add(light2);

    root = new THREE.Group();
    scene.add(root);

    renderer = new THREE.WebGLRenderer({
      antialias: true,
      preserveDrawingBuffer: true
    });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(aspect, aspect);
    rootElem.innerHTML = '';
    rootElem.appendChild(renderer.domElement);

    labelRenderer = new CSS2DRenderer();
    labelRenderer.setSize(aspect, aspect);
    labelRenderer.domElement.style.position = "absolute";
    labelRenderer.domElement.style.top = "-16px";
    labelRenderer.domElement.style.pointerEvents = "none";
    rootElem.appendChild(labelRenderer.domElement);

    controls = new TrackballControls(camera, renderer.domElement);

    loadMolecule(`${trailingSlashIt(window.location.origin)}${path}`);

    // listening to custom event triggered in graph.js && browser print event
    document.body.addEventListener('setCanvasImage', () => {
      makeStaticImg();
    });
    window.addEventListener("beforeprint", () => {
      makeStaticImg();
    });

    window.addEventListener("resize", onWindowResize);
  }

  function loadMolecule(url) {
    while (root.children.length > 0) {
      const object = root.children[0];
      object.parent.remove(object);
    }

    loader.load(url, (pdb) => {
      const geometryAtoms = pdb.geometryAtoms;
      const geometryBonds = pdb.geometryBonds;
      const json = pdb.json;

      const boxGeometry = new THREE.BoxGeometry(1, 1, 1);
      const sphereGeometry = new THREE.IcosahedronGeometry(1, 3);

      geometryAtoms.computeBoundingBox();
      geometryAtoms.boundingBox.getCenter(offset).negate();
      geometryAtoms.translate(offset.x, offset.y, offset.z);
      geometryBonds.translate(offset.x, offset.y, offset.z);

      let positions = geometryAtoms.getAttribute("position");
      const colors = geometryAtoms.getAttribute("color");
      const position = new THREE.Vector3();
      const color = new THREE.Color();

      for (let i = 0; i < positions.count; i++) {
        position.x = positions.getX(i);
        position.y = positions.getY(i);
        position.z = positions.getZ(i);

        color.r = colors.getX(i);
        color.g = colors.getY(i);
        color.b = colors.getZ(i);

        const material = new THREE.MeshPhongMaterial({ color: color });

        const object = new THREE.Mesh(sphereGeometry, material);
        object.position.copy(position);
        object.position.multiplyScalar(75);
        object.scale.multiplyScalar(25);
        root.add(object);

        const atom = json.atoms[i];

        const text = document.createElement("div");
        text.className = "label";
        text.style.color =
          "rgb(" + atom[3][0] + "," + atom[3][1] + "," + atom[3][2] + ")";
        text.textContent = atom[4];

        const label = new CSS2DObject(text);
        label.position.copy(object.position);
        root.add(label);
      }

      positions = geometryBonds.getAttribute("position");

      const start = new THREE.Vector3();
      const end = new THREE.Vector3();

      for (let i = 0; i < positions.count; i += 2) {
        start.x = positions.getX(i);
        start.y = positions.getY(i);
        start.z = positions.getZ(i);

        end.x = positions.getX(i + 1);
        end.y = positions.getY(i + 1);
        end.z = positions.getZ(i + 1);

        start.multiplyScalar(75);
        end.multiplyScalar(75);

        const object = new THREE.Mesh(
          boxGeometry,
          new THREE.MeshPhongMaterial(0xffffff)
        );
        object.position.copy(start);
        object.position.lerp(end, 0.5);
        object.scale.set(5, 5, start.distanceTo(end));
        object.lookAt(end);
        root.add(object);
      }

      render();
    },
    // while loading callback
    () => {
      return;
    },
    ( error ) => {
      console.log( 'An error happened:', error );
    });
  }

  function onWindowResize() {
    camera.aspect = aspect / aspect;
    camera.updateProjectionMatrix();

    renderer.setSize(aspect, aspect);
    labelRenderer.setSize(aspect, aspect);

    render();
  }

  function animate() {
    requestAnimationFrame(animate);
    controls.update();

    const time = Date.now() * 0.0004;

    root.rotation.x = time;
    root.rotation.y = time * 0.7;

    render();
  }

  function render() {
    renderer.render(scene, camera);
    labelRenderer.render(scene, camera);
  }

  const makeStaticImg = () => {
    const strMime = "image/jpeg";
    const imgData = renderer.domElement.toDataURL(strMime);

    imgData.replace(strMime, 'image/octet-stream');
    staticImg.setAttribute('src', imgData);
  };
};

export default makeMolecule;
