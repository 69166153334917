import React from 'react';
import DropdownCombobox from './dropdown-combobox';
import Disclaimer from './disclaimer';
import { useWizard } from './context/context';
import { isBlank } from './utilities/utilities';

import ligands from '../data/ligands';
import metals from '../data/metals';

const PrintLabels= ({label}) => {
  return (
    <span
      aria-hidden="true"
      tabIndex="-1"
      className="label-print-only"
    >
      {label}
    </span>
  )
}


const AlloySelect = () => {
  const {
    acceptedDisclaimer,
    setSubmitted,
    setMetal,
    setLigand,
    hasSubmitted,
    selectedMetal,
    selectedLigand
  } = useWizard();

  const hasValues = !isBlank(selectedMetal) && !isBlank(selectedLigand) && acceptedDisclaimer ? true : false;

  const handleOnClick = (e) => {
    if (hasSubmitted) {
      setMetal(selectedMetal);
      setLigand(selectedLigand);
    } else {
      setMetal(selectedMetal);
      setLigand(selectedLigand);
      setSubmitted(hasValues);
    }

    e.target.blur()
  };

  return (
    <div className="alloy-select">
      <PrintLabels label="metal" />
      <DropdownCombobox
        listItems={metals}
        label="Select
        metal"
        name="metals"
        printLabel="metal"
      />
      <PrintLabels label="ligand" />
      <DropdownCombobox
        listItems={ligands}
        label="Select ligand"
        name="ligands"
        printLabel="ligand"
      />
      <Disclaimer />
      <button
        type="button"
        disabled={!hasValues}
        onClick={handleOnClick}
        className="btn btn-primary btn-filled btn--cta"
      >
        React
      </button>
    </div>
  );
};

export default AlloySelect;
