import React from 'react';
import { useWizard } from './context/context';

const Hero = ({ children }) => {
  const { hasSubmitted } = useWizard();
  const isActiveClass = hasSubmitted ? 'is-active' : 'inactive';

  return (
    <div className={`hero ${isActiveClass}`}>
      <div className="container">
        {children}
        {hasSubmitted &&
        <a className="link--scroll" href="#contact-rasirc">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 30">
            <defs/>
            <g fill="#FFF" opacity=".431">
              <path d="M11.4380129 30H9.56198711C4.2931518 30 0 25.911387 0 20.8935751V9.1064249C0 4.08861296 4.2931518 0 9.56198711 0h1.87602579C16.7068482 0 21 4.08861296 21 9.1064249v11.7871502C21 25.911387 16.7068482 30 11.4380129 30zM9.56198711 1.71819338c-4.25815132 0-7.7578386 3.2994811-7.7578386 7.38823152v11.7871502c0 4.088613 3.49975945 7.3882315 7.7578386 7.3882315h1.87602579c4.2931518 0 7.7578386-3.3330202 7.7578386-7.3882315V9.1064249c0-4.08861296-3.4997595-7.38823152-7.7578386-7.38823152H9.56198711z"/>
              <path d="M14.7920208 16.4130244c-.3109725-.3547241-.8653124-.3547241-1.2114374 0l-2.2146459 2.4830687-.001352-9.00928283C11.3645855 9.39076408 10.9832943 9 10.4992731 9c-.4840211 0-.86531236.39076408-.86531236.88681027V18.932275l-2.21464593-2.4830688c-.31097251-.3547241-.86531239-.3547241-1.21143735 0-.27716995.3186984-.27716995.8161493 0 1.1348334l3.6696168 4.1499173c.31097254.3547241.86531244.3547241 1.21143734 0l3.7031909-4.1499173c.27717-.3547241.27717-.8507703 0-1.1708733l-.0001017-.0001419z"/>
            </g>
          </svg>
        </a>
        }
      </div>
    </div>
  );
};

export default Hero;
