import React from 'react';

import AlloySelect from './alloy-select';
import ContactForm from './contact-form';
import Graph from './graph';
import Hero from './hero';
import Intro from './intro';
import Molecule from './molecule';
import { WizardProvider } from './context/context';

import '../css/app.css';

function App() {
  return (
    <WizardProvider>
      <main className="app">
        <Hero>
          <Intro />
          <Graph />
          <Molecule />
          <AlloySelect />
        </Hero>
        <ContactForm />
      </main>
    </WizardProvider>
  );
}

export default App;
