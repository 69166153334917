const isBlank = (str) => (str === '' || str === "" || !str || /^\s*$/.test(str));

const formulaFormat = (str) => {
  const subbedString = str.split('').map((s) => {
    if (Number.isInteger(parseInt(s))) {
      return `<sub>${s}</sub>`
    }

    return s;
  });

  return subbedString.join('');
};

const findNearestHundred = (arr) => {
  const n = arr.map((num) => Math.abs(num));
  const largest = Math.max(...n);
  const t = Math.ceil(largest / 100) * 100;

  return t;
}

const getPercentage = (num, scale) => Math.round((Math.abs(num) * 100) / scale);

const getDirectionClass = (num) => Math.sign(num) > 0 ? 'is-pos' : 'is-neg';

const trailingSlashIt = (url) => url.replace(/\/?$/, '/');

export {
  isBlank,
  formulaFormat,
  findNearestHundred,
  getPercentage,
  getDirectionClass,
  trailingSlashIt
};
